<template>
  <base-section
    id="medicos-certificados"
  >
    <!--<v-img
      :src="require('@/assets/compac/logo.png')"
      class="mx-auto mb-8"
      max-width="178"
    />-->

    <base-section-heading title="Patólogos Clínicos Certificados">
      <div align="right" class="mt-n6">
        <v-chip
          class="ma-2"
          color="transparent"
          large
          label
        >
          <v-img
            :src="require('@/assets/compac/medalla.svg')"
            max-width="72"
            class="ml-2"
          ></v-img>
          <span class="ml-7 font-weight-bold" style="color: dimgrey">
           <h3 class="font-weight-bold mb-0 grey--text mb-4 mt-3" style="color: #42A5F5">
           Total de Certificados {{total_certificados}}
          </h3>
          </span>
        </v-chip>
      </div>

    </base-section-heading>

    <v-container>
      <v-row>
        <v-col cols="12" class="mt-n5">
          <h2 class="font-weight-bold mb-0 red--text text-left mb-4" >
            Consulta de Patólogos Certificados
          </h2>

        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="5" class="mr-n8">
          <v-text-field
            v-model="medicoName"
            outlined
            v-bind="$attrs"
            v-on="$listeners"
            label="Ingrese el Nombre del Patólogo"
            dense
            hide-details
            class="mr-n5"
          />
        </v-col>
        <v-col cols="12" md="7" class="ml-n3 mt-0" align="right">
         <v-btn
            color="blue"
            dark
            @click="InitialCurrent(),findByName()"
            small
          >
           <v-icon>mdi-magnify</v-icon>
         </v-btn>

          <v-chip
            color="green lighten-5"
            label
            class="mx-3 my-1"
            :ripple="true"
          >
            <v-checkbox
              v-model="vigentes"
              label="Certificación Vigente"
              color="green darken-3"
              hide-details
              class="mt-n5 mb-n5"
              @click="getMedicos()"
              @change="finados=false, current=1"
            ></v-checkbox>
          </v-chip>

          <v-chip
            color="red lighten-4"
            label
            class="mx-3"
          >
            <v-checkbox
              v-model="novigentes"
              label="Certificación Vencida"
              color="red darken-3"
              hide-details
              class="mt-n5 mb-n5"
              @click="getMedicos()"
              @change="finados=false, current=1"

            ></v-checkbox>
          </v-chip>

          <v-chip
            color="light-blue lighten-4"
            label
            class="mx-3"
          >
            <v-checkbox
              v-model="finados"
              label="Finados"
              color="#2196F3"
              hide-details
              class="mt-n5 mb-n5"
              @click="getMedicos()"
              @change="vigentes=false, novigentes=false, current=1"
            ></v-checkbox>
          </v-chip>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-divider></v-divider>
          <h3 class="font-weight-bold mb-0 grey--text mb-0 mt-3" style="color: #42A5F5">
            Resultados
          </h3>
        </v-col>
        <v-col cols="12" md="4">
        </v-col>
      </v-row>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          class="mt-n6"
          cols="12"
          md="12"
        >
      
        <v-data-table
            dense
            :headers="headers"
            :items="medicos_array"
            item-key="medico"
            class="elevation-1"
            light
            :loading="loading"
            :page="current"
            :items-per-page="itemsPerPage"
            hide-default-footer
            @page-count="pageCount = $event"
            
          >
          <template v-slot:medico.status="{ medico }">
              <v-chip
                  class="ma-2"
                  :color="getColor(medico.status)"
                  text-color="white"
                  small
                >
                  <v-avatar left>
                    <v-icon>mdi-checkbox-marked-circle</v-icon>
                  </v-avatar>
                  
                </v-chip>
            </template>
            <template v-slot:item.status="{ item }">
              <v-chip
                  class="ma-2"
                  :color="getColor(item.status)"
                  text-color="white"
                  small
                >
                  {{ item.status}} 
              </v-chip>
            </template>
        </v-data-table>
          <!--<v-simple-table dense  
            :page="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            @page-count="pageCount = $event"
          >
            <thead>
            <tr>
              <th class="text-body-1 font-weight-bold">
                Nombre
              </th>
              <th class="text-body-1 font-weight-bold">
                Certificación
              </th>
              <th class="text-body-1 font-weight-bold">
                Última Recertificación
              </th>
              <th class="text-body-1 font-weight-bold">
                Próxima Recertificación
              </th>
              <th class="text-body-1 font-weight-bold">
                Número de Certificado COMPAC
              </th>
              <th class="text-body-1 font-weight-bold">
                Tipo Asociado
              </th>
              <th class="text-body-1 font-weight-bold">
                Estatus
              </th>
            </tr>
            </thead>
            <tbody class="text-body-1">
            <tr
              v-for="(medico, key ) in medicos_array"
              :key="'lis_medico_'+ key"
              dense
            >

              <td>{{medico.ncompleto}}</td>
              <td>{{medico.anho_certificacion}}</td>
              <td>{{medico.anho_revalidacion}}</td>
              <td>{{medico.anho_prox_revalidacion}}</td>
              <td>{{medico.clave}}</td>
              <td>{{medico.tipo}}</td>
              <td v-if="medico.status=='Vigente'">
                <v-chip
                  class="ma-2"

                  color="green"
                  text-color="white"
                  small
                >
                  <v-avatar left>
                    <v-icon>mdi-checkbox-marked-circle</v-icon>
                  </v-avatar>
                  {{medico.status}}

                </v-chip>
              </td>

              <td v-if="medico.status=='No Vigente'">
                <v-chip
                  class="ma-2"

                  color="red"
                  text-color="white"
                  small
                >
                  <v-avatar left>
                    <v-icon>mdi-information</v-icon>
                  </v-avatar>
                  {{medico.status}}

                </v-chip>
              </td>

              <td v-if="medico.status=='Finado'">
                <v-chip
                  class="ma-2"

                  color="#03A9F4"
                  text-color="white"
                  small
                >
                  <v-avatar left>
                    <v-icon>mdi-cross</v-icon>
                  </v-avatar>
                  {{medico.status}}

                </v-chip>
              </td>
            </tr>
            </tbody>
          </v-simple-table>-->
          <v-pagination
              class="mt-3" 
              v-model="current" 
              :length="total" 
              @input="onPageChange" 
              :total-visible='7'
              color="red"
          >
          </v-pagination>

        </v-col>
        

      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  const axios = require('axios').default;
  import qs from "qs";

  export default {
    name: 'SectionMedicosCertificados',

    data: () => ({
      conback:process.env.VUE_APP_BACK_URL,
      total_certificados:0,
      medicoName:'',
      vigentes:true,
      novigentes:false,
      finados:false,
      vigentesynovigentes:false,
      parametros:{},
      currentMedico:{},
      currentYear: new Date().getFullYear(),
      medicos_array:[],
      colorFinado:'#dceff7',
      colorVigente:'#DFECE1',
      colorNOVigente:'#F5C6CB',

    headers: [
            { text: 'Nombre', value: 'ncompleto' },
            { text: 'Certificación', value: 'anho_certificacion' },
            { text: 'Última Recertificación', value: 'anho_revalidacion' },
            { text: 'Próxima Recertificación', value: 'anho_prox_revalidacion' },
            { text: 'Número de Certificado COMPAC', value: 'clave', sortable: false },
            { text: 'Tipo Asociado', value: 'tipo' },
            { text: 'Estatus', value: 'status', sortable: false }
          ],
      loading:false,
     // page:1,
      pageCount: 0,
      current:1,
      itemsPerPage: 10,
      total:0,
      buscandox:'filtros'

    }),
    mounted() {
      this.getMedicos();
    },
    wacth:{
      //para saber cuando resetera el currentePage a 1
      buscandox(){
        return alert(this.buscandox)
        //console.log("la OLD busqueda",newBusqueda)

      }

    },
    
    methods:{
      onPageChange(){
        //sera necesario sabe si se esta buscanco por nombre o por algun filtro
        //si se busca por filtro debera ejecutarse this.getMedicos()
        //si se busca por el nombre se ejecutar el findByName()
        //this.getMedicos()
        
        //este se puede mejorrar haciendo una sola funcin
        
        if(this.buscandox == "filtros")
        {
          this.getMedicos()
        }else{          
          this.findByName()
        }
      },
      getColor(status){
        if (status === "Vigente"){          
          return 'green'
        }else if(status === "No Vigente"){
          return 'red'
        }else{
          return 'blue'
        }

      },
      InitialCurrent(){
        //esta funcion sirve solamente para dejar en 1 a current page y cuando se busca a un medico
        //por el nombre 
        this.current = 1
      },
      findByName()
      {
        
        this.buscandox ="nombre"
        this.vigentes=false,
        this.novigentes=false 
        this.finados=false
        //this.current = 1
        var that = this
        this.currentMedico = {}

        if(this.medicoName.length < 3)
        {
          that.$swal({
            icon: 'info',
            title: 'Ingrese por lo menos tres letras del nombre',
            confirmButtonText: "Aceptar"
          })
          return
        }

        let parametros = {
          page:this.current,
          nombre:this.medicoName
        }

        axios.get(this.conback+'/medicoscertificados', {params:parametros})
          .then(function (response) {
            that.medicos_array=[];
            that.total_certificados = response.data.total_certificados;
            that.current = response.data.medicos.current_page;
            that.total = response.data.medicos.last_page;

            for(var i= 0; i <= response.data.medicos.data.length -1; i++){
              that.currentMedico = response.data.medicos.data[i];
              that.currentMedico.color='';

              if(that.currentMedico.anho_revalidacion == 0)
              {
                that.currentMedico.anho_revalidacion = '———'
              }


              //vigentes
              if(that.currentMedico.anho_prox_revalidacion >= that.currentYear)
              {
                that.currentMedico.color =that.colorVigente
                that.currentMedico.status= 'Vigente'
              }

              //NOvigentes
              if(that.currentMedico.anho_prox_revalidacion < that.currentYear)
              {
                that.currentMedico.color =that.colorNOVigente
                that.currentMedico.status='No Vigente'
              }

              if(that.currentMedico.tipo == 'Finado')
              {
                that.currentMedico.color =that.colorFinado
                that.currentMedico.status= 'Finado'
              }


              that.medicos_array.push(that.currentMedico)
            }
          })
          .catch(function (error) {
          console.log(error)
        });

      },
      getMedicos(){
        this.buscandox="filtros"
        var that = this
        this.currentMedico = {}
        //this.vigentesynovigentes = (this.vigentes == true && this.novigentes == true) ? true : undefined

        let parametros2 = {
          page:this.current,
          finados:this.finados || undefined,
          vigentes:this.vigentes || undefined,
          novigentes:this.novigentes || undefined,
        }

        axios.get(this.conback+'/medicoscertificados', {params:parametros2})
          .then(function (response) {
            that.medicos_array=[];
            that.total_certificados = response.data.total_certificados;
            that.current = response.data.medicos.current_page;
            that.total = response.data.medicos.last_page;

            for(var i= 0; i <= response.data.medicos.data.length -1; i++){
              that.currentMedico = response.data.medicos.data[i];
              that.currentMedico.color='';

              if(that.currentMedico.anho_revalidacion == 0)
              {
                that.currentMedico.anho_revalidacion = '———'
              }


              //vigentes
              if(that.currentMedico.anho_prox_revalidacion >= that.currentYear)
              {
                that.currentMedico.color =that.colorVigente
                that.currentMedico.status= 'Vigente'
              }

              //NOvigentes
              if(that.currentMedico.anho_prox_revalidacion < that.currentYear)
              {
                that.currentMedico.color =that.colorNOVigente
                that.currentMedico.status='No Vigente'
              }

              if(that.currentMedico.tipo == 'Finado')
              {
                that.currentMedico.color =that.colorFinado
                that.currentMedico.status= 'Finado'
              }


              that.medicos_array.push(that.currentMedico)
            }

          })
          .catch(function (error) {
            console.log(error)
          });
      }

    }
  }
</script>
